import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { XIcon } from '@heroicons/react/solid';

const StoryDialog = ({ onClose, onContinue }) => {
  const [showReason, setShowReason] = useState(false);
  const [choice, setChoice] = useState(null);
  const [reason, setReason] = useState('');
  const [showFinalMessage, setShowFinalMessage] = useState(false);

  const handleSubmit = () => {
    setShowFinalMessage(true);
    setTimeout(() => {
      onContinue();
    }, 3000);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/80 backdrop-blur-sm z-[60] flex items-center justify-center p-4"
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="bg-white rounded-2xl p-6 max-w-md w-full relative"
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
        >
          <XIcon className="h-6 w-6" />
        </button>

        <AnimatePresence mode="wait">
          {!showReason && !showFinalMessage && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="text-center"
            >
              <h3 className="text-2xl font-bold text-love mb-4">
                Would you like to hear the stories behind these pictures?
              </h3>
              <p className="text-gray-600 mb-6">
                Each picture holds a special memory, a moment frozen in time. Would you like to know more?
              </p>
              <div className="flex gap-4 justify-center">
                <button
                  onClick={() => {
                    setChoice('yes');
                    setShowReason(true);
                  }}
                  className="px-6 py-2 bg-love text-white rounded-full hover:bg-pink-600 transition-colors"
                >
                  Yes, tell me more!
                </button>
                <button
                  onClick={() => {
                    setChoice('no');
                    setShowReason(true);
                  }}
                  className="px-6 py-2 bg-gray-200 text-gray-700 rounded-full hover:bg-gray-300 transition-colors"
                >
                  No, thanks
                </button>
              </div>
            </motion.div>
          )}

          {showReason && !showFinalMessage && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="text-center"
            >
              <h3 className="text-2xl font-bold text-love mb-4">
                {choice === 'yes' ? "That's wonderful!" : "Are you sure?"}
              </h3>
              <p className="text-gray-600 mb-4">
                {choice === 'yes' 
                  ? "What makes you curious about our story?" 
                  : "Why don't you want to hear these stories?"}
              </p>
              <textarea
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                className="w-full p-3 border rounded-lg mb-4 h-32 resize-none"
                placeholder="Share your thoughts..."
              />
              <button
                onClick={handleSubmit}
                className="px-6 py-2 bg-love text-white rounded-full hover:bg-pink-600 transition-colors"
              >
                Submit
              </button>
            </motion.div>
          )}

          {showFinalMessage && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="text-center"
            >
              <h3 className="text-2xl font-bold text-love mb-4">
                {choice === 'yes' 
                  ? "Let's begin this beautiful journey..." 
                  : "Well,I will tell you anyway 😝😝..."}
              </h3>
              <p className="text-gray-600">
                {choice === 'yes'
                  ? "Get ready to experience some magical moments!"
                  : "Let me show you anyway..."}
              </p>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </motion.div>
  );
};

export default StoryDialog; 