import React from 'react';
import { HeartIcon } from '@heroicons/react/solid';

const Loader = () => {
  return (
    <div className="fixed inset-0 bg-gradient-to-r from-pink-300 to-red-300 flex flex-col items-center justify-center z-50">
      <div className="animate-pulse-slow">
        <HeartIcon className="h-16 w-16 text-white animate-float" />
      </div>
      <p className="mt-6 text-white text-2xl font-medium opacity-0 animate-fade-in">
        Loading my love for you... ✨🫶
      </p>
    </div>
  );
};

export default Loader; 