import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { HeartIcon, PhotographIcon, PaperAirplaneIcon } from '@heroicons/react/solid';
import Loader from './components/Loader';
import GallerySlideshow from './components/GallerySlideshow';
import HeartCursor from './components/HeartCursor';
import StoryDialog from './components/StoryDialog';
import { Fireworks } from 'fireworks-js';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [showGallery, setShowGallery] = useState(false);
  const [showStoryDialog, setShowStoryDialog] = useState(false);
  const [currentMessage, setCurrentMessage] = useState(0);
  const [showContent, setShowContent] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [showFireworks, setShowFireworks] = useState(false);

  const messages = [
    {
      title: "My Dearest Love ❤️",
      content: "This is the first time I am doing this, I don't know how to say this, take care of me as you take care of yourself."
    },
    {
      title: "My Special Request from you 💖",
      content: "Up until now, we barely spent time together, but from now on, I want to set some ourtime to spend together and grow our love."
    },
    {
      title: "Funny but True💝",
      content: "Sometimes even periods can be irregular, but my thoughts about you are always regular."
    },
    {
      title: "My Forever 💕",
      content: "Every beat of my heart whispers your name. Every moment spent with you is a treasure I'll cherish forever. You're my today and all of my tomorrows."
    }
  ];

  const images = [
    {
      url: 'img1.jpg',
      story: "This is the picture that you said was your favorite in your entire gallery, and that makes it special for me too. ❤️"
    },
    {
      url: 'img2.jpg',
      story: "Remember this day? This is the first time I have seen you in traditional attire, and I was so amazed by how beautiful you looked. Though you ignored what I said at the end of the day, still makes it a special day for me. 💕"
    },
    {
      url: 'img3.jpg',
      story: "I never said one thing to you that I litterally stalk you everywhere,😁😝. And this is a picture that I got in process, you are smiling naturally and I can't take my eyes off you. So I added this to my secret collection. hehe 😁😁 💝"
    },
    {
      url: 'img4.jpg',
      story: "I want to make you feel the same way you are while taking this picture, I can't help but smile when I look at this. ✨"
    },
    {
      url: 'img5.jpg',
      story: "This picture may be blur, but my feelings for you are crystal clear. Any time when you give dharshan in traditional dress, ahhhhhhh I am out of my control. 💝"
    },
    {
      url: 'img6.jpg',
      story: "This is the first picture that I got from you, you said it was not that good, you don't know how beautiful you are in this picture. 😒🫠, please take a good look at that picture and tell me how it is. "
    },
    {
      url: 'img7.jpg',
      story: "You may get silver , but you are always the gold to me 💫"
    },
    {
      url: 'main.jpg',
      story: "This picture perfectly captures our love - pure, joyful, and real. The way you look at me makes me feel like the luckiest person in the world. 💖"
    },
    {
      url: 'img8.jpg',
      story: "Don't worry I will always back you up 🤭☀️"
    },
    {
      url: 'img9.jpeg',
      story: "I Never told you the reason I loved you right?. I think after seeing this picture, you will know the reason. I really thought of just being friends with you, but then whithout even knowing I fell for you. And from that moment I made you fall in love with me. Remember the first one to express their love is you not me. 😁🤭🫶"
    },
    {
      url: 'img10.jpg',
      story: "This is the first time when I heard something that I didn't expect from you. I was like did she really said that 😮. But that still didn't happened anyway "
    },
    {
      url: 'img11.jpg',
      story: "This is one of the pictures I got from different source. But that's not the point, this is the first time where you attended an event in college where I didn't."
    }
  ];

  useEffect(() => {
    const preloadImages = async () => {
      try {
        const imagePromises = images.map(img => {
          return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = `/images/${img.url}`;
            image.onload = resolve;
            image.onerror = reject;
          });
        });
        await Promise.all(imagePromises);
        setTimeout(() => {
          setIsTransitioning(true);
          setTimeout(() => {
            setIsLoading(false);
            setShowContent(true);
            setIsTransitioning(false);
          }, 1000);
        }, 3000);
      } catch (error) {
        console.error('Error preloading images:', error);
        setIsLoading(false);
        setShowContent(true);
      }
    };

    preloadImages();
  }, []);

  useEffect(() => {
    if (showFireworks) {
      const container = document.getElementById('fireworks-container');
      const fireworks = new Fireworks(container, {
        explosion: 12,
        intensity: 40,
        traceLength: 3,
        traceSpeed: 10,
        rocketsPoint: {
          min: 20,
          max: 80
        },
        hue: {
          min: 0,
          max: 360
        },
        delay: {
          min: 10,
          max: 20
        },
        brightness: {
          min: 60,
          max: 90
        },
        decay: {
          min: 0.015,
          max: 0.03
        },
        mouse: {
          click: false,
          move: false,
          max: 1
        }
      });

      fireworks.start();

      return () => {
        fireworks.stop();
      };
    }
  }, [showFireworks]);

  const nextMessage = () => {
    setCurrentMessage((prev) => (prev + 1) % messages.length);
  };

  const handleGalleryClick = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.style.position = 'fixed';
    canvas.style.top = '0';
    canvas.style.left = '0';
    canvas.style.pointerEvents = 'none';
    canvas.style.zIndex = '100';
    document.body.appendChild(canvas);

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };
    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    const hearts = Array.from({ length: 30 }, () => ({
      x: Math.random() * canvas.width,
      y: canvas.height,
      size: Math.random() * 15 + 5,
      speedY: Math.random() * -5 - 3,
      speedX: Math.random() * 4 - 2,
      opacity: 1
    }));

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      let stillAnimating = false;

      hearts.forEach(heart => {
        if (heart.opacity > 0) {
          stillAnimating = true;
          heart.y += heart.speedY;
          heart.x += heart.speedX;
          heart.opacity -= 0.02;

          ctx.fillStyle = `rgba(255, 20, 147, ${heart.opacity})`;
          ctx.beginPath();
          ctx.moveTo(heart.x, heart.y);
          const size = heart.size;
          ctx.bezierCurveTo(
            heart.x - size/2, heart.y - size/2,
            heart.x - size, heart.y + size/3,
            heart.x, heart.y + size
          );
          ctx.bezierCurveTo(
            heart.x + size, heart.y + size/3,
            heart.x + size/2, heart.y - size/2,
            heart.x, heart.y
          );
          ctx.fill();
        }
      });

      if (stillAnimating) {
        requestAnimationFrame(animate);
      } else {
        document.body.removeChild(canvas);
        window.removeEventListener('resize', resizeCanvas);
        setShowStoryDialog(true);
      }
    };

    animate();
  };

  if (isLoading || isTransitioning) {
    return (
      <motion.div
        initial={{ opacity: 1 }}
        animate={{ opacity: isTransitioning ? 0 : 1 }}
        transition={{ duration: 1 }}
      >
        <Loader />
      </motion.div>
    );
  }

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="min-h-screen w-screen flex flex-col items-center justify-center relative overflow-hidden"
      style={{
        backgroundImage: "url('/images/collection.jpg')",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        width: '100%'
      }}
    >
      <HeartCursor />
      <div className="absolute inset-0 bg-black/50" />
      
      <motion.div
        className="text-center relative z-10 w-full max-w-4xl mx-auto px-4 py-8"
      >
        <motion.div
          className="mb-6 md:mb-8 relative group"
          style={{ 
            marginLeft: "-60px",
            top: "20px",
            transform: "translateX(-10px)"
          }}
        >
          <motion.div
            animate={{
              scale: [1, 1.2, 1],
              rotate: [0, 5, -5, 0]
            }}
            transition={{
              duration: 4,
              repeat: Infinity,
              ease: "easeInOut"
            }}
          >
            <HeartIcon className="h-14 w-14 md:h-20 md:w-20 text-red-500 mx-auto relative z-10" />
          </motion.div>
        </motion.div>

        <motion.h1 
          className="text-2xl md:text-5xl font-bold text-white mb-6 md:mb-8"
        >
          Happy Valentine's Day!
        </motion.h1>

        <div className="relative w-full max-w-2xl mx-auto">
          <AnimatePresence initial={false} mode="wait" custom={currentMessage}>
            <motion.div
              key={currentMessage}
              custom={currentMessage}
              initial={{ opacity: 0, x: 200 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -200 }}
              transition={{
                x: { type: "spring", stiffness: 300, damping: 30 },
                opacity: { duration: 0.2 }
              }}
              onClick={nextMessage}
              className="bg-white/20 backdrop-blur-sm p-6 rounded-2xl mb-8 cursor-pointer 
                hover:bg-white/30 transition-colors shadow-xl hover:shadow-2xl 
                transform hover:-translate-y-1"
            >
              <h3 className="text-xl md:text-2xl font-bold text-white mb-4">
                {messages[currentMessage].title}
              </h3>
              <p className="text-base md:text-lg text-white/90 leading-relaxed">
                {messages[currentMessage].content}
              </p>
              
              <div className="mt-4 text-white/60 text-sm flex items-center justify-center gap-2">
                <span>Click to read next</span>
                <motion.span
                  animate={{ x: [0, 5, 0] }}
                  transition={{ duration: 1, repeat: Infinity }}
                >
                  →
                </motion.span>
              </div>
            </motion.div>
          </AnimatePresence>
        </div>

        <div className="flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-4 justify-center">
          <motion.button
            whileHover={{ scale: 1.05, y: -3 }}
            whileTap={{ scale: 0.95 }}
            transition={{ type: "spring", stiffness: 400 }}
            className="bg-white text-love px-4 md:px-6 py-3 rounded-full font-semibold shadow-lg flex items-center justify-center space-x-2"
            onClick={handleGalleryClick}
          >
            <PhotographIcon className="h-5 w-5" />
            <span>Pictures</span>
          </motion.button>

          <motion.button
            whileHover={{ scale: 1.05, y: -3 }}
            whileTap={{ scale: 0.95 }}
            transition={{ type: "spring", stiffness: 400 }}
            className="bg-love text-white px-4 md:px-6 py-3 rounded-full font-semibold shadow-lg flex items-center justify-center space-x-2"
            onClick={() => {
              setShowFireworks(true);
              setShowMessage(false);
              setTimeout(() => {
                setShowFireworks(false);
                setShowMessage(true);
              }, 5000);
            }}
          >
            <PaperAirplaneIcon className="h-5 w-5 transform rotate-90" />
            <span>Send Love</span>
          </motion.button>
        </div>

        <AnimatePresence>
          {showFireworks && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              className="fixed inset-0 bg-black z-50"
            >
              <div 
                id="fireworks-container" 
                className="w-full h-full"
                style={{ background: 'transparent' }}
              />
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {showMessage && (
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5 }}
              className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50"
              onClick={() => setShowMessage(false)}
            >
              <motion.div
                className="bg-white p-4 md:p-6 rounded-lg max-w-[90vw] md:max-w-md text-love"
                onClick={e => e.stopPropagation()}
                whileHover={{ scale: 1.02 }}
              >
                <h3 className="text-xl md:text-2xl font-bold mb-4">My Secret Message 💌</h3>
                <p className="text-gray-700 text-sm md:text-base">
                  Everyday from the moment you left me in college, the only constant thought that my mind always have is you. I can't say that you make my life complete with your love, but your presence is something that I want to cherish forever.
                  If you ask me what i like in you, I may say something like your smile, and your beautiful heart your caring, something like that, but that's not the reason I loved you, I am still figuring out what is the one thing or something special that I noticed in you that others didn't find.
                  I don't want to admit, but You became a need for me, not a want. You are always free to go , because I heard a quote from a movie that says if something is yours set it free, if it comes back it's yours, if it doesn't it never was yours.I always have this strong feeling that whatever happens, you will come back to me and be with me.
                </p>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>

      <AnimatePresence>
        {showStoryDialog && (
          <StoryDialog 
            onClose={() => setShowStoryDialog(false)}
            onContinue={() => {
              setShowStoryDialog(false);
              setShowGallery(true);
            }}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showGallery && (
          <GallerySlideshow 
            images={images} 
            onClose={() => setShowGallery(false)} 
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
}

export default App; 