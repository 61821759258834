import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { HeartIcon } from '@heroicons/react/solid';

const HeartCursor = () => {
  const [position, setPosition] = useState({ x: -100, y: -100 });
  const [trail, setTrail] = useState([]);
  const [isMoving, setIsMoving] = useState(false);
  let moveTimeout;

  const updatePosition = useCallback((e) => {
    const newPosition = { x: e.clientX - 12, y: e.clientY - 12 };
    setPosition(newPosition);
    
    // Add to trail only if mouse is moving
    setTrail(prevTrail => {
      const newTrail = [...prevTrail, { 
        x: e.clientX - 12, 
        y: e.clientY - 12, 
        id: Date.now() 
      }];
      return newTrail.slice(-5); // Keep only last 5 positions
    });

    // Set moving state
    setIsMoving(true);
    clearTimeout(moveTimeout);
    moveTimeout = setTimeout(() => setIsMoving(false), 100);
  }, []);

  useEffect(() => {
    window.addEventListener('mousemove', updatePosition);
    return () => {
      window.removeEventListener('mousemove', updatePosition);
      clearTimeout(moveTimeout);
    };
  }, [updatePosition]);

  return (
    <>
      {/* Trail hearts */}
      <AnimatePresence>
        {isMoving && trail.map((point, index) => (
          <motion.div
            key={point.id}
            className="custom-cursor"
            initial={{ 
              scale: 1,
              opacity: 0.7,
              x: point.x,
              y: point.y
            }}
            animate={{ 
              scale: 0,
              opacity: 0,
              x: point.x,
              y: point.y
            }}
            exit={{ opacity: 0, scale: 0 }}
            transition={{
              duration: 0.5,
              ease: "easeOut"
            }}
            style={{
              position: 'fixed',
              left: 0,
              top: 0,
              zIndex: 9999 - index
            }}
          >
            <HeartIcon 
              className="cursor-heart-trail" 
              style={{ 
                width: `${18 - index * 2}px`, 
                height: `${18 - index * 2}px`,
                color: `rgba(255, 20, 147, ${0.5 - index * 0.1})`,
                transform: `rotate(${index * 5}deg)`
              }} 
            />
          </motion.div>
        ))}
      </AnimatePresence>

      {/* Main cursor heart */}
      <motion.div
        className="custom-cursor"
        animate={position}
        transition={{
          type: "spring",
          mass: 0.2,
          stiffness: 800,
          damping: 25
        }}
      >
        <HeartIcon 
          className="cursor-heart"
          style={{
            filter: 'drop-shadow(0 0 4px rgba(255,20,147,0.7))'
          }}
        />
      </motion.div>
    </>
  );
};

export default HeartCursor; 