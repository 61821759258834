import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { XIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

const GallerySlideshow = ({ images, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);

  const nextSlide = () => {
    setCurrentIndex((prev) => (prev + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prev) => (prev - 1 + images.length) % images.length);
  };

  useEffect(() => {
    let interval;
    if (autoPlay) {
      interval = setInterval(nextSlide, 5000); // Change slide every 5 seconds
    }
    return () => clearInterval(interval);
  }, [autoPlay]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Escape') {
        onClose();
      } else if (e.key === 'ArrowLeft') {
        prevSlide();
        setAutoPlay(false);
      } else if (e.key === 'ArrowRight') {
        nextSlide();
        setAutoPlay(false);
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [onClose, prevSlide, nextSlide]);

  const messages = [
    "Every picture tells our story...",
    "Each moment with you is precious...",
    "These memories light up my world...",
    "Your smile makes everything better...",
    "Together, we create magic...",
    "Our love story continues...",
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 z-50"
      onClick={onClose}
      style={{
        background: 'radial-gradient(circle at center, rgba(255,20,147,0.2) 0%, rgba(0,0,0,0.95) 100%)',
        backdropFilter: 'blur(10px)'
      }}
    >
      <div 
        className="min-h-screen relative flex flex-col items-center justify-center p-4"
        onClick={e => e.stopPropagation()}
      >
        {/* Close button with better visibility */}
        <motion.button
          whileHover={{ scale: 1.1, rotate: 90 }}
          whileTap={{ scale: 0.9 }}
          onClick={onClose}
          className="absolute top-4 right-4 bg-white/10 p-2 rounded-full z-50 hover:bg-white/20 
            flex items-center gap-2 px-4"
        >
          <XIcon className="h-6 w-6 text-white" />
          <span className="text-white text-sm hidden md:inline">Press ESC or click outside to close</span>
        </motion.button>

        {/* Main content */}
        <div className="w-full max-w-6xl mx-auto flex flex-col items-center">
          {/* Image container */}
          <div className="relative w-full max-w-3xl mb-8">
            <AnimatePresence mode="wait">
              <motion.img
                key={currentIndex}
                src={`/images/${images[currentIndex].url}`}
                alt={`Memory ${currentIndex + 1}`}
                className="w-full object-contain max-h-[60vh] rounded-2xl shadow-2xl mx-auto"
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -100 }}
                transition={{ type: "spring", stiffness: 200, damping: 20 }}
              />
            </AnimatePresence>

            {/* Story text */}
            <motion.p
              key={`story-${currentIndex}`}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="text-white text-lg md:text-xl text-center mt-6 px-4 leading-relaxed"
            >
              {images[currentIndex].story}
            </motion.p>

            {/* Navigation buttons */}
            <button
              className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-black/30 p-2 
                rounded-full hover:bg-black/50 transition-colors"
              onClick={(e) => {
                e.stopPropagation();
                prevSlide();
                setAutoPlay(false);
              }}
            >
              <ChevronLeftIcon className="h-6 w-6 text-white" />
            </button>
            <button
              className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-black/30 p-2 
                rounded-full hover:bg-black/50 transition-colors"
              onClick={(e) => {
                e.stopPropagation();
                nextSlide();
                setAutoPlay(false);
              }}
            >
              <ChevronRightIcon className="h-6 w-6 text-white" />
            </button>
          </div>

          {/* Progress bar */}
          <div className="w-full max-w-md h-1 bg-white/20 rounded-full overflow-hidden">
            <motion.div
              className="h-full bg-white"
              initial={{ width: 0 }}
              animate={{ width: `${((currentIndex + 1) / images.length) * 100}%` }}
              transition={{ duration: 0.3 }}
            />
          </div>

          {/* Image counter */}
          <p className="text-white/80 mt-4">
            {currentIndex + 1} / {images.length}
          </p>
        </div>
      </div>
    </motion.div>
  );
};

export default GallerySlideshow; 